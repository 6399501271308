.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-item::before {
  content: "標準" !important;
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "見出し1" !important;
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "見出し2" !important;
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "見出し3" !important;
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-label::before {
  content: "標準" !important;
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
  content: "見出し1" !important;
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
  content: "見出し2" !important;
}

html[lang='ja'] .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
  content: "見出し3" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-item::before {
  content: "표준" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "제목1" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "제목2" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "제목3" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-label::before {
  content: "표준" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
  content: "제목1" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
  content: "제목2" !important;
}

html[lang='ko'] .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
  content: "제목3" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header .ql-picker-item::before {
  content: "标准" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header  .ql-picker-item[data-value="2"]::before {
  content: "标题2" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header  .ql-picker-item[data-value="3"]::before {
  content: "标题3" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header .ql-picker-label::before {
  content: "标准" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
  content: "标题1" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
  content: "标题2" !important;
}

html[lang='zh-CN'] .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
  content: "标题3" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header .ql-picker-item::before {
  content: "標準" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header  .ql-picker-item[data-value="1"]::before {
  content: "標題1" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header  .ql-picker-item[data-value="2"]::before {
  content: "標題2" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header  .ql-picker-item[data-value="3"]::before {
  content: "標題3" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header .ql-picker-label::before {
  content: "標準" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
  content: "標題1" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
  content: "標題2" !important;
}

html[lang='zh-TW'] .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
  content: "標題3" !important;
}

/* Sans Serif translations */
html[lang='ja'] .ql-picker.ql-font .ql-picker-item::before, html[lang='ja'] .ql-picker.ql-font .ql-picker-label::before {
  content: "サンセリフ";
}

html[lang='zh-CN'] .ql-picker.ql-font .ql-picker-item::before, html[lang='zh-CN'] .ql-picker.ql-font .ql-picker-label::before {
  content: "无衬线字体";
}

html[lang='zh-TW'] .ql-picker.ql-font .ql-picker-item::before, html[lang='zh-TW'] .ql-picker.ql-font .ql-picker-label::before {
  content: "無襯線字體";
}

/* Serif translations */
html[lang='ja'] .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before, html[lang='ja'] .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: "セリフ"
}

html[lang='zh-CN'] .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before, html[lang='zh-CN'] .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: "衬线"
}

html[lang='zh-TW'] .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before, html[lang='zh-TW'] .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: "襯線"
}

/* Monospace translations */
html[lang='ja'] .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before, html[lang='ja'] .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: "モノスペース"
}

html[lang='zh-CN'] .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before, html[lang='zh-CN'] .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: "等宽"
}

html[lang='zh-TW'] .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before, html[lang='zh-TW'] .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: "等寬"
}
